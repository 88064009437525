import { useMutation } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { CurrentUserContext } from '../../../contexts/currentUserContext';
import {
  UserProfessionContext,
  UserProfessionContextProvider
} from '../../../contexts/userProfessionContext';
import { GET_CURRENT_USER } from '../../../graphql/GET_CURRENT_USER';
import { UPDATE_USER_PROFILE } from '../../../graphql/UPDATE_USER_PROFILE';
import FormField from '../../FormField';
import { ECollaborationStatus } from '../CollaborationStatus';
import UserAvatar from './UserAvatar';
import UserSkillForm from './UserSkillForm';

const OnboardingForm: React.FunctionComponent = () => {
  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => {
      toast.success('User profile successfully updated.');
    }
  });

  const user = useContext(CurrentUserContext);

  const renderForm = ({
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    setFieldValue,
    errors
  }: FormikValues) => {
    return (
      <>
        <header className="content">
          <h1 className="is-size-4">
            Whoohoo, <span>{user.profile.name}</span>, make yourself at home!
            <br />
            Let’s quickly create your profile.
          </h1>
          <span className="is-small">
            For now we just need some basic information, but of course,
            <br />
            you'll get the chance to add some sprinkles later.
          </span>
        </header>
        <div className="m-b-2 is-flex">
          <UserAvatar avatar={user.avatar} />
        </div>
        <UserProfessionContextProvider professions={user.professions}>
          <OnBoardingUserProfession setFieldValue={setFieldValue} />
        </UserProfessionContextProvider>
        {errors.professions && (
          <p className="error is-small">This field is required</p>
        )}
        <UserSkillForm skills={user.skills} />
        <form onSubmit={handleSubmit} className="">
          <div className="field">
            <div className="field-body">
              <FormField
                additionalFieldClasses={['is-size-8', 'is-uppercase']}
                additionalInputClasses="has-background-white"
                label="Add a short bio"
                multiline={true}
                name={'bio'}
                placeholder={
                  'Eg. I am a web designer specializing in designing interactive websites. I would love to take my design work to the next level with a motion graphics artist. Coffee lover.'
                }
              />
            </div>
          </div>
          <div className="field">
            <div className="field-body">
              <div className="field">
                <div className="label is-uppercase is-size-8">Availability</div>
                <div className="control">
                  <div className="select is-small">
                    <select
                      className="has-background-white"
                      name="collaborationStatus"
                      onChange={handleChange}
                      value={values.collaborationStatus}
                    >
                      <option value={ECollaborationStatus.available}>
                        Available
                      </option>
                      <option value={ECollaborationStatus.looking}>
                        Looking for collaborations
                      </option>
                      <option value={ECollaborationStatus.unavailable}>
                        Unavailable
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className={`button has-text-weight-bold is-primary ${isSubmitting &&
                'is-loading'}`}
              disabled={isSubmitting}
            >
              Done
            </button>
          </div>
        </form>
      </>
    );
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        bio: user.profile.bio ?? undefined,
        collaborationStatus: user.profile.collaborationStatus ?? undefined,
        professions: []
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await updateUserProfile({
          update: (cache, { data: { editUserProfile } }) => {
            if (!editUserProfile) {
              return cache;
            }

            // const { me } = cache.readQuery<GetCurrentUser, any>({
            //   query: GET_CURRENT_USER
            // })!;

            // cache.writeData({
            //   data: {
            //     me: {
            //       ...me,
            //       editUserProfile
            //     }
            //   }
            // });

            cache.writeQuery({
              query: GET_CURRENT_USER,
              data: {
                me: {
                  // ...me,
                  editUserProfile
                }
              }
            });

            window.location.replace('/');
          },
          variables: {
            ...values,
            isOnboarded: true,
            name: `${user.profile.name}`
          }
        });
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        professions: Yup.array().min(1)
      })}
    >
      {formikData => renderForm(formikData)}
    </Formik>
  );
};

function OnBoardingUserProfession(props: { setFieldValue: any }) {
  const { professionNames } = useContext(UserProfessionContext);

  useEffect(() => {
    props.setFieldValue('professions', professionNames);
  }, [professionNames.length]);

  return null;
}

export default OnboardingForm;
