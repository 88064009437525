import { sanitize } from 'dompurify';
import React, { FunctionComponent } from 'react';
import { parseUrl } from '../services/url';

interface IPrettyContentProps {
  content: string;
  isLineBreakEnabled: boolean;
}

export const PrettyContent: FunctionComponent<IPrettyContentProps> = ({
  content,
  isLineBreakEnabled
}) => (
  <div className={isLineBreakEnabled ? 'enable-line-break' : ''}>
    <p
      dangerouslySetInnerHTML={{
        __html: parseUrl(sanitize(content))
      }}
    />
  </div>
);
