import React, { FunctionComponent, useContext } from 'react';
import Moment from 'react-moment';
import { Link, useLocation } from 'react-router-dom';
// import useReactRouter from 'use-react-router';

import { MessageContext } from '../../contexts/messageContext';
import { calendarStrings } from '../../utils/dateTime';
import { MessageContentLoader } from '../ContentLoader';
import { useRoomListsContext } from '../../contexts/roomListsContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from '../Spinner';

interface IMessageUserListProps {
  setOpenRoom: React.Dispatch<React.SetStateAction<boolean>>;
}

const MAX_RECENT_MESSAGE_LENGTH = 81;

const MessageUserList: FunctionComponent<IMessageUserListProps> = (props) => {
  const { actions } = useContext(MessageContext);
  const {
    rooms,
    loading,
    error,
    handleFetchNextRoomPage,
    hasMoreRoomsToFetch
  } = useRoomListsContext();

  const location = useLocation();
  const [, roomId] = location.pathname.split('/messages/');
  const { setOpenRoom } = props;

  if (loading) {
    return (
      <div className="p-t-1 p-b-1 p-r-1 p-l-1">
        <MessageContentLoader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-t-1 p-b-1 p-r-1 p-l-1">
        <p>Something went wrong.</p>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={rooms.length}
      hasMore={hasMoreRoomsToFetch}
      loader={<Spinner />}
      scrollThreshold={0.1}
      next={async () => await handleFetchNextRoomPage()}
      scrollableTarget="scrollableDiv"
    >
      {rooms.map((r, k) => {
        const otherUser = actions.getOtherUser(r.members);

        return (
          <li
            key={k}
            className={`page-messages-column-list-item p-t-1 p-b-1 p-r-1 p-l-2 ${
              roomId === r.id ? 'selected' : ''
            }`}
          >
            <Link to={`/messages/${r.id}`} onClick={() => setOpenRoom(true)}>
              <div className="level m-b-0">
                <div className="level-left">
                  <div className="level-item content">
                    <p
                      className={`page-messages-column-list-item-sender m-b-0 is-small page-messages-column-list-item-title ${
                        r.hasUnreadMessage ? 'unread' : ''
                      }`}
                    >
                      {otherUser && otherUser.profile && otherUser.profile.name}
                    </p>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <span className="content is-small page-messages-column-list-item-time">
                      <Moment format="D MMM YYYY" calendar={calendarStrings}>
                        {r.createdAt}
                      </Moment>
                    </span>
                  </div>
                </div>
              </div>
              <p className="page-messages-column-list-item-content is-small">
                {r.recentMessage
                  ? r.recentMessage.length > MAX_RECENT_MESSAGE_LENGTH
                    ? `${r.recentMessage.substr(
                        0,
                        MAX_RECENT_MESSAGE_LENGTH
                      )}...`
                    : r.recentMessage
                  : '......'}
              </p>
            </Link>
          </li>
        );
      })}
    </InfiniteScroll>
  );
};

export default MessageUserList;
