import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Moment from 'react-moment';
import { Link, useRouteMatch } from 'react-router-dom';

import profilePlaceholder from '../../assets/images/profile/profile-placeholder.png';
import { MessageContext } from '../../contexts/messageContext';
import { GET_ROOM_getRoom_messages_author } from '../../graphql/types/GET_ROOM';
import {
  GET_USER_getUser,
  GET_USER_getUser_professions
} from '../../graphql/types/GET_USER';
import { useScrollToBottom } from '../../hooks/useScrollToBottom';
import { calendarStrings } from '../../utils/dateTime';
import { getCompressedImagePath } from '../../utils/getCompressedImagePath';
import { PrettyContent } from '../Content';
import { MessageContentLoader } from '../ContentLoader';
import MessageReplyForm from './MessageReplyForm';
interface IMessageItemProps {
  content: string;
  createdAt: string;
  author: GET_ROOM_getRoom_messages_author;
  otherUser: GET_USER_getUser;
}

const MessageItem: FunctionComponent<IMessageItemProps> = ({
  author,
  createdAt,
  content,
  otherUser
}) => {
  const isAuthoredByCurrentUser = author.id !== otherUser.id;
  const avatar = author.avatar
    ? author.avatar && author.avatar.fileUrl
    : otherUser.avatar && otherUser.avatar.fileUrl;

  return (
    <div className='page-messages-column-card-content-item is-rounded m-b-1'>
      <article className="media">
        <figure className="media-left">
          <p className="image is-42x42">
            <img
              src={getCompressedImagePath(avatar, 'thumbnail') || profilePlaceholder}
              alt="profile placeholder"
              className="image is-inline is-rounded-circle is-42x42"
            />
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <p className="has-text-weight-semibold">
              {isAuthoredByCurrentUser ? 'You' : otherUser.profile.name}
            </p>
            <p className="is-small is-light">
              <Moment calendar={calendarStrings} format="LT">
                {createdAt}
              </Moment>
            </p>
          </div>
        </div>
      </article>
      <PrettyContent content={content} isLineBreakEnabled={true} />
    </div>
  );
};

interface IRoomParams {
  roomId?: string;
}

const MessageContent = () => {
  const messageListRef = useRef<HTMLDivElement>(null);
  useScrollToBottom(messageListRef);
  const [otherUser, setOtherUser] = useState<any>(null);
  const { actions, currentRoom } = useContext(MessageContext);
  const { params } = useRouteMatch<IRoomParams>();

  useEffect(() => {
    if (currentRoom.data && currentRoom.data.getRoom.members.length) {
      setOtherUser(actions.getOtherUser(currentRoom.data.getRoom.members));
    }
  }, [currentRoom.data, currentRoom.loading]); // eslint-disable-line

  useEffect(() => {
    if (params.roomId) {
      actions.handleLoadRoom(params.roomId);
    }
  }, [params.roomId]); // eslint-disable-line

  // Update readAtMessages when messages comes in
  useEffect(() => {
    if (
      currentRoom.data &&
      currentRoom.data.getRoom &&
      currentRoom.data.getRoom.messages.length > 0
    ) {
      actions.handleReadAtMessages(
        currentRoom.data.getRoom.messages.map(m => Number(m.id)),
        Number(currentRoom.data.getRoom.id)
      );
    }
  }, [currentRoom.data]); // eslint-disable-line

  if (currentRoom.loading) {
    return <MessageContentLoader />;
  }

  if (!currentRoom.loading && currentRoom.error) {
    return <div className="notification is-danger">Something went wrong.</div>;
  }

  const otherUserAvatarImage = otherUser && otherUser.avatar && otherUser.avatar.fileUrl;
  const otherUserCompressedAvatar = getCompressedImagePath(otherUserAvatarImage, 'thumbnail');
  return (
    <Fragment>
      <div className="level page-messages-column-content-header is-bordered-bottom m-b-0">
        {otherUser && otherUser.profile && (
          <div className="level-left">
            <div className="level-item">
              <article className="media align-items-left">
                <figure className="media-left">
                  <img
                    src={otherUserCompressedAvatar || profilePlaceholder}
                    alt="profile"
                    className="image is-inline is-rounded-circle is-48x48"
                    // This helps change the Picture in the messages
                  />
                </figure>
                <div className="media-content">
                  <div className="content">
                    <Link to={`/user/${otherUser.id}`}>
                      <p className="has-text-weight-semibold">
                        {otherUser.profile.name}
                      </p>
                    </Link>
                    <p>
                      {otherUser.professions &&
                        otherUser.professions.map(
                          (
                            profession: GET_USER_getUser_professions,
                            key: number
                          ) => (
                            <span key={key} className="is-small is-light">
                              {(key ? ', ' : '') + profession.name}
                            </span>
                          )
                        )}
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </div>
        )}
      </div>
      {currentRoom.data && currentRoom.data.getRoom && otherUser && (
        <div
          className="card-content page-messages-column-card-content p-t-1 p-b-0 p-l-0"
          ref={messageListRef}
        >
          {currentRoom.data.getRoom.messages.map((m, idx) => (
            <MessageItem key={idx} {...m} otherUser={otherUser} />
          ))}
        </div>
      )}
      <MessageReplyForm />
    </Fragment>
  );
};

export default MessageContent;
