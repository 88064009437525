import { QueryResult, useMutation } from '@apollo/client';
import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldArrayRenderProps,
  Formik,
  FormikValues
} from 'formik';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { toast } from 'react-toastify';
// import useReactRouter from 'use-react-router';
import * as Yup from 'yup';

import globeGrey from '../../../assets/images/project/globe-grey.svg';
import globeRed from '../../../assets/images/project/globe-red.svg';
import usersGrey from '../../../assets/images/project/users-grey.svg';
import usersRed from '../../../assets/images/project/users-red.svg';
import coinGrey from '../../../assets/svg/coin-grey.svg';
import coinWhite from '../../../assets/svg/coin-white.svg';
import heartGrey from '../../../assets/svg/heart-grey.svg';
import heartRed from '../../../assets/svg/heart-red.svg';
import plusCircleIcon from '../../../assets/svg/plus-circle.svg';
import trashDangerIcon from '../../../assets/svg/trash-danger.svg';
import { GET_PROJECT } from '../../../graphql/GET_PROJECT';
import { GET_ALL_PROJECTS } from '../../../graphql/GET_ALL_PROJECTS';
import { ADD_PROJECT } from '../../../graphql/ADD_PROJECT';
import { DELETE_PROJECT } from '../../../graphql/DELETE_PROJECT';
import { GET_SKILLS_BY_FILTER_QUERY } from '../../../graphql/GET_SKILLS_BY_FILTER_QUERY';
import { UPDATE_PROJECT } from '../../../graphql/UPDATE_PROJECT';
import {
  IHandleOptionSelect,
  useAutocomplete
} from '../../../hooks/useAutocomplete';
import { IClientUser } from '../../../interfaces/user';
import { validVideoUrlRegex } from '../../../services/videoUrlParser';
import FormField, { Checkbox } from '../../FormField';
import UploadForm, { EUploadTypes } from '../../Upload';
import autoCompleteStyles from '../../User/Forms/autocompleteStyles';
import * as projectConstants from './constants';
import UploadPhotoContainer from './UploadPhotoContainer';
import StoryboardGenerationForm, {
  StoryboardGeneratedImageTable
} from './StoryboardGenerationForm';

export enum EFormTypes {
  new = 'new',
  update = 'update'
}

enum EProjectTypes {
  active = 'active',
  completed = 'completed'
}

export interface IProjectFormProps extends IClientUser {
  formType?: EFormTypes;
  project?: any;
}

interface IHandleAddNewSkillParams {
  fieldName: string;
  value: string;
  fieldArray: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any;
}

const projectValidationSchema = Yup.object().shape({
  description: Yup.string().required('This field is required'),
  title: Yup.string().required('This field is required'),
  videoUrl: Yup.string()
    .matches(
      validVideoUrlRegex,
      'Input must be a valid Youtube or Vimeo video URL.'
    )
    .nullable()
});

const ProjectForm: React.FunctionComponent<IProjectFormProps> = (
  props: IProjectFormProps
) => {
  const history = useHistory();
  const addProjectCompleted = async (data) => {
    return toast.success(
      'New project created! Redirecting to the project page...',
      {
        onClose: () => history.push(`/project/${data.addProject.id}`),
        autoClose: 5000
      }
    );
  };

  const [deleteProjectModalIsActive, setDeleteProjectModalIsActive] =
    useState<boolean>(false);
  const [savedMedia, setSavedMedia] = useState<any[]>([]);

  const deleteProjectCompleted = () =>
    toast.success('Project successfully deleted.', {
      onClose: () => history.replace('/')
    });

  const updateProjectCompleted = (data: any) =>
    toast.success('Project successfully updated!', {
      onClose: () => history.push(`/project/${data.editProject.id}`)
    });

  const [addProject] = useMutation<QueryResult>(ADD_PROJECT, {
    onCompleted: addProjectCompleted,
    refetchQueries: [GET_PROJECT, { query: GET_ALL_PROJECTS }]
  });

  const [deleteProject] = useMutation<QueryResult>(DELETE_PROJECT, {
    onCompleted: deleteProjectCompleted,
    refetchQueries: ['GET_PROJECT']
  });

  const cancelProjectUpdate = () => {
    if (props.project && props.project.id) {
      history.push(`/project/${props.project.id}`);
      return;
    }
    history.push('/');
  };

  const [updateProject] = useMutation(UPDATE_PROJECT, {
    onCompleted: updateProjectCompleted,
    refetchQueries: ['GET_PROJECT']
  });

  const handleDeleteProject = async () => {
    await deleteProject({
      variables: {
        projectId: parseInt(props.project.id, 10)
      }
    });
  };

  const deleteProjectModal = () => {
    return (
      <div
        className={`modal is-danger ${
          deleteProjectModalIsActive && 'is-active'
        }`}
      >
        <div
          className="modal-background"
          onClick={() => setDeleteProjectModalIsActive(false)}
        />
        <div className="modal-content">
          <div className="box">
            <strong className="m-b-2 is-block">
              Are you sure you want to delete this project?
            </strong>
            <div className="columns">
              <div className="column">
                <button
                  type="button"
                  className="button is-light"
                  onClick={() => setDeleteProjectModalIsActive(false)}
                >
                  No
                </button>
              </div>
              <div className="column">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={handleDeleteProject}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSKillInputChange = (
    params: IHandleOptionSelect,
    action: object,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any,
    skillsArray: FieldArrayRenderProps
  ) => {
    if (!params) {
      return;
    }

    const { __isNew__, label, value } = params;

    handleAddNewSkill({
      fieldArray: skillsArray,
      // @ts-expect-error
      fieldName: action.name,
      setFieldValue,
      value: __isNew__ ? value : label
    });
  };

  const renderCollaborationForm = (
    arrayHelpers: ArrayHelpers,
    collabIdx: number,
    values: FormikValues,
    handleBlur: (e: any) => void,
    handleChange: (eventOrTextValue: React.ChangeEvent<any>) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => any
  ) => (
    <Fragment key={collabIdx}>
      <div className="level m-b-0">
        <div className="level-left">
          <div className="level-item">
            <p className="has-text-weight-bold is-size-8 is-light is-uppercase">
              Collaborator {collabIdx + 1}
            </p>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <p
              className="has-text-weight-bold has-text-danger has-cursor-pointer is-size-7"
              onClick={() => arrayHelpers.remove(collabIdx)}
            >
              - Remove
            </p>
          </div>
        </div>
      </div>
      <div
        className={`card page-add-project-card-content-collaborators-card has-background-grey ${
          values.collaborations.length - 1 !== collabIdx && 'm-b-1'
        }`}
      >
        <div className="card-content">
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="field-body has-flex-direction-column">
                  <label className="label is-size-8 is-uppercase has-text-weight-bold">
                    Profession
                    <span className="has-text-danger">*</span>
                  </label>
                  <Fragment>
                    <FieldArray
                      name={`collaborations.${collabIdx}.requiredSkills`}
                    >
                      {(skillsArray) => {
                        return (
                          <Fragment>
                            <AsyncCreatableSelect
                              cacheOptions={true}
                              isClearable={true}
                              className="is-small"
                              escapeClearsValue={true}
                              backspaceRemovesValue={true}
                              isLoading={loading}
                              name={`collaborations.${collabIdx}.requiredNewSkill`}
                              onChange={(params, action) =>
                                handleSKillInputChange(
                                  params,
                                  action,
                                  setFieldValue,
                                  skillsArray
                                )
                              }
                              loadOptions={loadOptions}
                              placeholder={'Add Profession...'}
                              styles={autoCompleteStyles}
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              value={
                                values.collaborations[collabIdx]
                                  .requiredNewSkill
                              }
                            />
                            <div className="columns is-marginless">
                              <div className="column p-l-0">
                                {values.collaborations[collabIdx]
                                  .requiredSkills &&
                                  values.collaborations[collabIdx]
                                    .requiredSkills.length > 0 &&
                                  values.collaborations[
                                    collabIdx
                                  ].requiredSkills.map(
                                    (skill: any, skillIdx: number) => (
                                      <span
                                        key={skillIdx}
                                        className="tag page-add-project-card-content-tag is-primary"
                                      >
                                        {skill}
                                        <button
                                          className="delete is-small"
                                          onClick={() =>
                                            skillsArray.remove(skillIdx)
                                          }
                                        />
                                      </span>
                                    )
                                  )}
                              </div>
                            </div>
                          </Fragment>
                        );
                      }}
                    </FieldArray>
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <div className="field-body has-flex-direction-column">
                  <label className="label is-size-8 is-uppercase has-text-weight-bold">
                    COMPENSATION
                  </label>
                  <Fragment>
                    <div className="control">
                      <div className="select page-add-project-card-content-select">
                        <select
                          className="has-background-white"
                          name={`collaborations.${collabIdx}.compensation`}
                          defaultValue={
                            values.collaborations[collabIdx].compensation
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                        >
                          {projectConstants.collaboratorCompensationTypeOptions.map(
                            (compensationType, idx) => {
                              return (
                                <option
                                  key={`${idx}-${compensationType.label}`}
                                  value={compensationType.value}
                                >
                                  {compensationType.label}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                  </Fragment>
                </div>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <div className="field">
                <div className="field-body has-flex-direction-column">
                  <label className="label is-size-8 is-uppercase has-text-weight-bold">
                    Profession you are looking for
                  </label>
                  <FormField
                    placeholder="Profession you are looking for..."
                    name={`collaborations.${collabIdx}.additionalRequirements`}
                    onChange={handleChange}
                    onBlur={handleBlur as any}
                    additionalInputClasses="has-background-white rows is-small"
                    value={
                      values.collaborations[collabIdx].additionalRequirements
                    }
                    multiline={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const handleAddNewSkill = ({
    fieldArray,
    fieldName,
    setFieldValue,
    value
  }: IHandleAddNewSkillParams) => {
    if (value && value.length > 0) {
      setFieldValue(fieldName, '');
      return fieldArray.push(value);
    }
  };

  const { loadOptions, loading } = useAutocomplete({
    query: GET_SKILLS_BY_FILTER_QUERY
  });

  const renderForm = (renderFormProps: FormikValues) => {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      setFieldValue
    } = renderFormProps;

    return (
      <div className="columns section page-add-project-container">
        <div className="column">
          <div className="card page-add-project-card is-rounded is-dropshadowed">
            <form onSubmit={handleSubmit}>
              <header className="card-header page-add-project-card-header is-bordered-bottom">
                <div className="card-header-title is-block">
                  <div className="level">
                    <div className="level-left">
                      <h1 className="has-text-weight-semibold is-light is-size-5">
                        {props.formType === EFormTypes.new ? 'New' : 'Update'}{' '}
                        Project
                      </h1>
                    </div>
                  </div>
                </div>
              </header>
              <div className="card-content page-add-project-card-content">
                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Share With<span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <div className="field">
                          <div className="control switchradio is-flex-desktop is-block-mobile">
                            {projectConstants.projectShareableOptions.map(
                              (shareableStatus) => (
                                <Fragment key={shareableStatus.value}>
                                  <Field
                                    type="radio"
                                    value={shareableStatus.value}
                                    id={shareableStatus.value}
                                    name="isPublic"
                                    className="switchradio-input is-absolute"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={
                                      values.isPublic === shareableStatus.value
                                    }
                                    required={true}
                                  />
                                  <label
                                    htmlFor={shareableStatus.value}
                                    className="button has-text-weight-bold switchradio-label p-t-1 p-b-1"
                                    key={shareableStatus.value}
                                  >
                                    <img
                                      src={
                                        shareableStatus.value ===
                                        projectConstants.projectShareableStatus
                                          .PUBLIC
                                          ? values.isPublic ===
                                            shareableStatus.value
                                            ? globeRed
                                            : globeGrey
                                          : values.isPublic ===
                                            shareableStatus.value
                                          ? usersRed
                                          : usersGrey
                                      }
                                      alt=""
                                      className="is-inline image"
                                    />
                                    <span className="is-small">
                                      {shareableStatus.label}
                                    </span>
                                  </label>
                                </Fragment>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Type <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <div className="field">
                          <div className="control switchradio is-flex-desktop is-block-mobile">
                            {projectConstants.projectTypeOptions.map(
                              (projectType) => (
                                <Fragment key={projectType.value}>
                                  <input
                                    type="radio"
                                    value={projectType.value}
                                    id={projectType.value}
                                    name="type"
                                    className="switchradio-input is-absolute"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    checked={values.type === projectType.value}
                                    required={true}
                                  />
                                  <label
                                    htmlFor={projectType.value}
                                    className="button has-text-weight-bold switchradio-label p-t-1 p-b-1"
                                    key={projectType.value}
                                  >
                                    <img
                                      src={
                                        projectType.value === 'PASSION'
                                          ? values.type === projectType.value
                                            ? heartRed
                                            : heartGrey
                                          : values.type === projectType.value
                                          ? coinWhite
                                          : coinGrey
                                      }
                                      alt=""
                                      className="is-inline image"
                                    />
                                    <span className="is-small">
                                      {projectType.label}
                                    </span>
                                  </label>
                                </Fragment>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Genre <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="page-add-project-card-content-genre is-grid">
                      {projectConstants.projectGenreOptions.map((genre) => (
                        <div className="field m-b-1" key={genre.value}>
                          <div className="field-body">
                            <div className="field m-b-0">
                              <div className="control">
                                <Checkbox
                                  label={genre.label}
                                  name="genres"
                                  value={genre.value}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Title <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <FormField
                          name="title"
                          type="text"
                          placeholder="Title"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          additionalInputClasses="has-background-white is-small"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Summary <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <FormField
                          multiline={true}
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Describe your project, what you want to accomplish, and why people should join your project."
                          value={values.description}
                          additionalInputClasses="has-background-white is-small"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Video URL
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <FormField
                          name="videoUrl"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Enter URL"
                          value={values.videoUrl}
                          additionalInputClasses="has-background-white is-small"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Location <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-icons-right page-add-project-card-content-control">
                            <FieldArray name="locations">
                              {(arrayHelper) => {
                                return (
                                  <Fragment>
                                    <Fragment>
                                      <FormField
                                        type="text"
                                        placeholder="Location"
                                        name="newLocation"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newLocation}
                                        additionalInputClasses="input page-add-project-card-content-input is-small has-background-white"
                                        iconRight={
                                          <span
                                            className="icon is-right"
                                            onClick={() => {
                                              if (values.newLocation) {
                                                arrayHelper.push(
                                                  values.newLocation
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              src={plusCircleIcon}
                                              alt="plus circle icon"
                                            />
                                          </span>
                                        }
                                      />
                                    </Fragment>
                                    {values.locations &&
                                      values.locations.map((loc, idx) => (
                                        <Fragment key={`${idx}-${loc}`}>
                                          <span className="tag page-add-project-card-content-tag is-primary">
                                            {loc}
                                            <button
                                              className="delete is-small"
                                              onClick={() =>
                                                arrayHelper.remove(idx)
                                              }
                                            />
                                          </span>
                                        </Fragment>
                                      ))}
                                  </Fragment>
                                );
                              }}
                            </FieldArray>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      I’m looking for
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body page-add-project-card-content-collaborators">
                        <FieldArray name="collaborations">
                          {(arrayHelpers) => (
                            <Fragment>
                              {values.collaborations &&
                                values.collaborations.length > 0 &&
                                values.collaborations.map(
                                  (collab, collabIdx: number) => {
                                    return renderCollaborationForm(
                                      arrayHelpers,
                                      collabIdx,
                                      values,
                                      handleBlur,
                                      handleChange,
                                      setFieldValue
                                    );
                                  }
                                )}
                              <div className="control control-collaboration">
                                <p
                                  onClick={() => {
                                    return arrayHelpers.push({
                                      additionalRequirements: '',
                                      compensation:
                                        projectConstants
                                          .collaboratorCompensationTypeOptions[0]
                                          .value,
                                      currency: 'JPY',
                                      requiredSkills: []
                                    });
                                  }}
                                >
                                  <span className="has-text-weight-bold has-text-link is-small">
                                    + Add collaborator
                                  </span>
                                </p>
                              </div>
                            </Fragment>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns m-b-2">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Storyboard Image
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <StoryboardGenerationForm
                              setSavedMedia={setSavedMedia}
                              setMediaFieldValue={(medias) =>
                                setFieldValue('medias', medias)
                              }
                            />
                            <UploadForm
                              medias={values.medias}
                              setFieldValue={setFieldValue}
                              uploadContainer={UploadPhotoContainer}
                              uploadType={EUploadTypes.project}
                              formType={props.formType!}
                              setSavedMedia={setSavedMedia}
                            />
                            <StoryboardGeneratedImageTable
                              setSavedMedia={setSavedMedia}
                              medias={values.medias}
                              setMediaFieldValue={(medias) =>
                                setFieldValue('medias', medias)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-quarter">
                    <h3 className="is-size-5 has-text-weight-bold">
                      Funding <span className="has-text-danger">*</span>
                    </h3>
                  </div>
                  <div className="column is-three-quarters">
                    <div className="field">
                      <div className="field-body">
                        <div className="control">
                          <div className="select page-add-project-card-content-select">
                            <select
                              className="has-background-white"
                              name="fundingStatus"
                              defaultValue={values.fundingStatus}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {projectConstants.projectFundingStatusOptions.map(
                                (funding, idx) => {
                                  return (
                                    <option
                                      key={`${idx}-${funding.label}`}
                                      value={funding.value}
                                    >
                                      {funding.label}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="navbar is-fixed-bottom page-add-project-controls is-bordered-top">
                <div className="level container page-add-project-controls-container">
                  <div className="level-left page-add-project-controls-container-level">
                    {props.formType === EFormTypes.update && (
                      <>
                        <button
                          type="button"
                          className="button is-fullwidth-mobile is-borderless page-add-project-controls-button has-background-white-ter has-text-weight-semibold has-text-danger"
                          onClick={() => setDeleteProjectModalIsActive(true)}
                        >
                          <img
                            src={trashDangerIcon}
                            alt="trash danger icon"
                            className="img icon"
                          />
                          <span className="is-small">Delete Project</span>
                        </button>
                        {deleteProjectModal()}
                      </>
                    )}

                    <button
                      type="button"
                      className="button is-fullwidth-mobile is-borderless page-add-project-controls-button has-background-white-ter has-text-weight-semibold has-text-primary"
                      onClick={() => {
                        cancelProjectUpdate();
                      }}
                    >
                      <span className="is-small">Cancel</span>
                    </button>
                  </div>
                  <div className="level-right page-add-project-controls-container-level">
                    <button
                      type="button"
                      className="button is-fullwidth-mobile is-borderless page-add-project-controls-button has-background-white-ter has-text-weight-semibold has-text-primary"
                    >
                      <span className="is-small">Save Draft</span>
                    </button>
                    <button
                      type="submit"
                      className={`button is-fullwidth-mobile page-add-project-controls-button has-text-weight-semibold is-primary ${
                        isSubmitting && 'is-loading'
                      }`}
                      disabled={isSubmitting}
                    >
                      <span className="is-small">
                        {props.formType === EFormTypes.new
                          ? 'Publish'
                          : 'Update'}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const getInitialValues = (formType: string) => {
    return formType === EFormTypes.new
      ? {
          collaborations: [
            {
              additionalRequirements: '',
              compensation:
                projectConstants.collaboratorCompensationTypeOptions[0].value,
              currency: 'JPY',
              requiredSkills: []
            }
          ],
          description: '',
          fundingStatus: projectConstants.projectFundingStatusOptions[0].value,
          genres: [],
          locations: [],
          medias: [],
          newLocation: '',
          progress: projectConstants.projectProgressOptions[0].value,
          requiredNewSkill: '',
          savedMedia,
          status: EProjectTypes.active,
          title: '',
          videoUrl: '',
          isPublic: projectConstants.projectShareableStatus.PRIVATE
        }
      : {
          collaborations: props.project.collaborations.map((collab) => ({
            additionalRequirements: collab.additionalRequirements,
            compensation: collab.compensation,
            id: collab.id ? collab.id : undefined,
            requiredSkills: collab.requiredSkills.map((skill) => skill.name)
          })),
          description: props.project.description,
          fundingStatus: props.project.fundingStatus,
          genres:
            props.project.genres && props.project.genres.length > 0
              ? props.project.genres.map((genre) => genre.name)
              : [],
          locations:
            props.project.locations && props.project.locations.length > 0
              ? props.project.locations.map((loc) => loc.name)
              : [],
          medias: props.project.medias,
          newLocation: '',
          progress: props.project.progress,
          requiredNewSkill: '',
          savedMedia,
          title: props.project.title,
          type: props.project.type,
          videoUrl: props.project.videoUrl,
          isPublic:
            props.project.isPublic === true
              ? projectConstants.projectShareableStatus.PUBLIC
              : projectConstants.projectShareableStatus.PRIVATE
        };
  };

  return (
    <React.Fragment>
      <Formik
        validationSchema={projectValidationSchema}
        enableReinitialize={EFormTypes.update === props.formType}
        initialValues={getInitialValues(props.formType!)}
        onSubmit={async (values, { setSubmitting }) => {
          const { savedMedia: savedMediaValue, medias, ...rest } = values;
          const sanitizeCollaborations = rest.collaborations.map((col) => {
            const sanitizeObj = {
              additionalRequirements: col.additionalRequirements,
              compensation: col.compensation,
              currency: 'JPY',
              professions: [],
              skills: col.requiredSkills
            };

            if (col.id) {
              return {
                ...sanitizeObj,
                id: col.id
              };
            }
            return sanitizeObj;
          });

          if (props.formType === EFormTypes.new) {
            await addProject({
              variables: {
                ...rest,
                isPublic:
                  rest.isPublic ===
                  projectConstants.projectShareableStatus.PUBLIC,
                collaborations: sanitizeCollaborations,
                medias: [...savedMedia.map((media) => parseInt(media.id))]
              }
            });
          } else {
            await updateProject({
              variables: {
                ...rest,
                isPublic:
                  rest.isPublic ===
                  projectConstants.projectShareableStatus.PUBLIC,
                collaborations: sanitizeCollaborations,
                id: props.project.id,
                medias: [
                  ...medias.map((media) => parseInt(media.id)),
                  ...savedMediaValue.map((media) => parseInt(media.id))
                ]
              }
            });
          }
          setSubmitting(false);
        }}
      >
        {(formikData) => renderForm(formikData)}
      </Formik>
    </React.Fragment>
  );
};

ProjectForm.defaultProps = {
  formType: EFormTypes.new
};

export default ProjectForm;
