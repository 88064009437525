import React, { useContext } from 'react';
import AsyncSelect from 'react-select/async';

import { MessageContext } from '../../contexts/messageContext';
import { GET_USERS_BY_FILTER_QUERY } from '../../graphql/GET_USERS_BY_FILTER_QUERY';
import { GET_USER_getUser } from '../../graphql/types/GET_USER';
import { useAutocomplete } from '../../hooks/useAutocomplete';

const MessageUserSearch = () => {
  const { actions } = useContext(MessageContext);

  const usersOptsSelectCb = async (otherUser: GET_USER_getUser) => {
    if (!otherUser) {
      return;
    }
    await actions.handleSelectOtherUser(otherUser);
  };

  const { loadOptions, loading, responseData } = useAutocomplete({
    query: GET_USERS_BY_FILTER_QUERY
  });

  return (
    <AsyncSelect
      backspaceRemovesValue={true}
      cacheOptions={true}
      className="is-small auto-complete"
      defaultOptions={true}
      escapeClearsValue={true}
      getOptionLabel={option => option.profile.name}
      getOptionValue={option => option.profile.id}
      isClearable={true}
      isLoading={loading}
      loadOptions={loadOptions}
      onChange={usersOptsSelectCb}
      options={responseData}
      placeholder="Search for Users"
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      value={null}
    />
  );
};

export default MessageUserSearch;
