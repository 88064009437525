import { useMutation } from '@apollo/client';
import React, { ComponentType, FunctionComponent, useEffect, useRef, useState } from 'react';
// import useReactRouter from 'use-react-router';

import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import profilePlaceholder from '../assets/images/profile/profile-placeholder.png';
import Logo from '../assets/logo.svg';
import bellIcon from '../assets/svg/bell.svg';
import mailIcon from '../assets/svg/mail.svg';
import searchIcon from '../assets/svg/search.svg';
import { GET_ALL_NOTIFICATIONS } from '../graphql/GET_ALL_NOTIFICATIONS';
import { GET_PROJECTS_BY_FILTER } from '../graphql/GET_PROJECTS_BY_FILTER';
import { READ_NOTIFICATION } from '../graphql/READ_NOTIFICATION';
import { GET_ALL_NOTIFICATIONS_getAllNotifications } from '../graphql/types/GET_ALL_NOTIFICATIONS';
import { useNotification } from '../hooks/useNotification';
import { IClientUser } from '../interfaces/user';
import Api from '../services/api';
import Notification, { NotificationModelTypes } from './Notification';
import searchForm from './User/Forms/searchForm';

import { GET_USERS_BY_FILTER_QUERY } from '../graphql/GET_USERS_BY_FILTER_QUERY';
import { useSearchAutocomplete } from '../hooks/useSearchAutoComplete';
import { getCompressedImagePath } from '../utils/getCompressedImagePath';

interface INavDropDownStates {
  roomNotification: boolean;
  projectNotification: boolean;
  profile: boolean;
}

export enum DropDownTypes {
  roomNotification = 'roomNotification',
  projectNotification = 'projectNotification',
  profile = 'profile'
}

export enum PathTypes {
  USER = 'user',
  PROJECT = 'project'
}
const mapDropDownTypeToModelType = {
  projectNotification: NotificationModelTypes.project,
  roomNotification: NotificationModelTypes.room
};

const dropDownInitState = {
  profile: false,
  projectNotification: false,
  roomNotification: false
};

const Group: ComponentType<any> = props => {
  const {
    Heading,
    getStyles,
    children,
    label,
    innerProps,
    headingProps,
    cx,
    theme
  } = props;
  return (
    <div aria-label={label} {...innerProps}>
      <Heading theme={theme} getStyles={getStyles} cx={cx} {...headingProps}>
        {label}
      </Heading>
      <div>{children}</div>
    </div>
  );
};

const Option = props => {
  const bgImage = props.data.firstOption
    ? null
    : {
        backgroundImage: `url(${getCompressedImagePath(
          props.data.avatar,
          'thumbnail'
        ) ||
          getCompressedImagePath(props.data.prjThumbImage, 'thumbnail') ||
          'https://via.placeholder.com/150'})`,
        backgroundSize: 'cover'
      };
  return (
    <components.Option {...props}>
      <div className="icon--is-inline-flex is-flex">
        <figure
          ref={props.innerRef}
          className={`${
            props.data.avatar ? 'avatar' : 'project'
          } image is-32x32`}
          style={bgImage!}
        />
        <div>
          <div style={{ fontWeight: 'bold', marginLeft: '1em' }}>
            {props.data.label}
          </div>
          <div style={{ marginLeft: '1em' }}>
            {props.data.professions &&
              props.data.professions.map((el, idx) => {
                const addCommaToSeparateWords =
                  props.data.professions.length === idx + 1 ? undefined : ', ';
                return (
                  <React.Fragment key={idx}>
                    <span
                      className="is-light is-small has-text-weight-light has-text-grey-dark"
                      key={idx}
                    >
                      {el.name}
                    </span>
                    {addCommaToSeparateWords}
                  </React.Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </components.Option>
  );
};

const Navigation: FunctionComponent<IClientUser> = (props: IClientUser) => {
  const { user } = props;
  const navRef = useRef<any>(null);
  const { notifications } = useNotification();
  const [flagReadNotification] = useMutation(READ_NOTIFICATION);
  const history = useHistory();
  const [isNavbarActive, setIsNavbarActive] = useState(false);
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const location = useLocation();
  const pattern = /^\/creatives|user/i;
  const pathname = location.pathname.match(pattern)
    ? PathTypes.USER
    : PathTypes.PROJECT;

  const assignQuery =
    pathname === PathTypes.USER
      ? GET_USERS_BY_FILTER_QUERY
      : GET_PROJECTS_BY_FILTER;

  const {
    loadOptions,
    loading,
    onChangeHandler,
    keyDownHandler,
    menuOpen,
    inputChangeHandler
  } = useSearchAutocomplete({
    query: assignQuery
  });

  const [dropDown, setDropDown] = useState<INavDropDownStates>(
    dropDownInitState
  );

  const handleDropDownClick = async (dType: DropDownTypes) => {
    setDropDown({
      ...dropDownInitState,
      [dType]: !dropDown[dType]
    });
    const { projectNotification, roomNotification } = DropDownTypes;

    if (dType === projectNotification || dType === roomNotification) {
      const readNotificationIds = filterReadNotificationIds(dType);

      await flagReadNotification({
        update: cache => flagReadNotificationCache(cache, readNotificationIds),
        variables: { notificationIds: readNotificationIds }
      });
    }
  };

  const filterReadNotificationIds = dType => {
    const modelType = mapDropDownTypeToModelType[dType];
    const filteredNotifications = filterNotificationsByModel(modelType);
    return filteredNotifications.map(
      (n: GET_ALL_NOTIFICATIONS_getAllNotifications) => Number(n.id)
    );
  };

  const flagReadNotificationCache = (cache, readNotificationIds) => {
    const { getAllNotifications } = cache.readQuery({
      query: GET_ALL_NOTIFICATIONS
    });
    const allNotifications = getAllNotifications?.map(
      (n: GET_ALL_NOTIFICATIONS_getAllNotifications) => {
        return {
          ...n,
          ...(n && readNotificationIds.includes(Number(n.id)) && {
            readAt: new Date()
          })
        };
      }
    );
    // Instantly update the notification counts
    cache.writeQuery({
      data: {
        getAllNotifications: allNotifications
      },
      query: GET_ALL_NOTIFICATIONS
    });
  };

  const backgroundImageUrl = user && user.avatar && user.avatar.fileUrl;

  const handleOutsideClick = (e: { target: any }) => {
    if (navRef.current && !navRef.current.contains(e.target)) {
      setDropDown(dropDownInitState);
    }
  };

  const filterNotificationsByModel = modelType => {
    return notifications.filter(
      (n: GET_ALL_NOTIFICATIONS_getAllNotifications) => n.model === modelType
    );
  };

  const filterUnReadNotificationsByModel = modelType => {
    return notifications.filter(
      (n: GET_ALL_NOTIFICATIONS_getAllNotifications) =>
        n.model === modelType && n.readAt === null
    );
  };

  const searchMobileProps = {
    imgAlt: 'search icon',
    imgSrc: searchIcon
  };

  const roomNotificationProps = {
    imgAlt: 'mail icon',
    imgSrc: mailIcon,
    isDropDownActive: dropDown.roomNotification,
    notifications: filterNotificationsByModel(NotificationModelTypes.room),
    unReadNotifications: filterUnReadNotificationsByModel(
      NotificationModelTypes.room
    )
  };

  const projectNotificationProps = {
    imgAlt: 'bell icon',
    imgSrc: bellIcon,
    isDropDownActive: dropDown.projectNotification,
    notifications: filterNotificationsByModel(NotificationModelTypes.project),
    unReadNotifications: filterUnReadNotificationsByModel(
      NotificationModelTypes.project
    )
  };

  const renderRightNav = () =>
    user ? (
      <>
        <NavLink
          exact={true}
          to="/"
          className="navbar-item is-small has-text-weight-semibold is-hidden-touch"
          activeClassName={'is-active'}
        >
          Projects
        </NavLink>
        <NavLink
          to="/creatives"
          className="navbar-item is-small has-text-weight-semibold is-hidden-touch"
          activeClassName={'is-active'}
        >
          Creatives
        </NavLink>
        {renderSearchMobile(searchMobileProps, () =>
          setIsSearchInputActive(!isSearchInputActive)
        )}
        {renderNotificationDropDown(
          DropDownTypes.roomNotification,
          roomNotificationProps
        )}
        {renderNotificationDropDown(
          DropDownTypes.projectNotification,
          projectNotificationProps
        )}
        {renderProfileDropDown()}
        <NavLink
          to="/project/new"
          className="navbar-item is-hidden-touch"
          activeClassName={'is-active'}
        >
          <button className="button navbar-item-button has-text-weight-bold is-small is-primary">
            New Project
          </button>
        </NavLink>
      </>
    ) : (
      <div className="buttons">
        <NavLink
          to="/sign-in"
          className="button is-primary has-height-auto is-outlined"
        >
          Login
        </NavLink>
        <NavLink to="/sign-up" className="button is-primary has-height-auto">
          Sign Up
        </NavLink>
      </div>
    );

  const renderSearchMobile = ({ imgSrc, imgAlt }, onClick) => {
    return (
      <div className="navbar-item" onClick={onClick}>
        <figure className="image badge is-relative">
          <img src={imgSrc} alt={imgAlt} />
        </figure>
      </div>
    );
  };

  const renderNotificationDropDown = (
    dropDownType: DropDownTypes,
    { imgAlt, imgSrc, isDropDownActive, notifications, unReadNotifications }
  ) => {
    const isRoomNotification = dropDownType === DropDownTypes.roomNotification;

    const handleNotificationItemClick = () => {
      if (isRoomNotification && notifications.length === 0) {
        return history.push('/messages');
      }

      return notifications.length > 0 && handleDropDownClick(dropDownType);
    };

    return (
      <div
        className={`navbar-item has-dropdown ${isDropDownActive &&
          'is-active'}`}
        onClick={handleNotificationItemClick}
      >
        <Link to="#" className="navbar-item">
          <figure
            className="image badge is-relative"
            data-badge={
              unReadNotifications.length === 0
                ? undefined
                : unReadNotifications.length
            }
          >
            <img src={imgSrc} alt={imgAlt} />
          </figure>
        </Link>
        <Notification
          notifications={notifications}
          dropDownType={dropDownType}
        />
      </div>
    );
  };

  const renderProfileDropDown = () => {
    return (
      <div
        className={`navbar-avatar navbar-item has-dropdown ${dropDown.profile &&
          'is-active'}`}
      >
        <Link
          to="#"
          className="navbar-item"
          onClick={async () => await handleDropDownClick(DropDownTypes.profile)}
        >
          <figure
            className="avatar image is-32x32"
            style={{
              backgroundImage: `url(${getCompressedImagePath(
                backgroundImageUrl!,
                'thumbnail'
              ) || profilePlaceholder})`
            }}
          />
        </Link>

        <div className="navbar-dropdown is-right">
          <NavLink
            to="/user"
            className="navbar-item is-small has-text-weight-semibold"
            activeClassName={'is-active'}
          >
            Profile & My Projects
          </NavLink>

          <hr className="navbar-divider" />
          <Link
            to={'#'}
            className="navbar-item is-small has-text-danger"
            onClick={Api.logout}
          >
            Sign Out
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div
      className="is-fixed-top navbar has-background-white has-flex-direction-column"
      ref={navRef}
    >
      <div className="container">
        <div className="navbar-brand">
          {user && user.profile && (
            <span
              role="button"
              className={`navbar-burger ${isNavbarActive ? 'is-active' : ''}`}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => setIsNavbarActive(!isNavbarActive)}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </span>
          )}
          <Link to="/" className="navbar-item">
            <img src={Logo} alt="MatchHat Logo" />{' '}
            <span className="beta">
              <span>beta</span>
            </span>
          </Link>
        </div>

        {user && (
          <div
            className="navbar-search"
            style={{ width: '500px', marginTop: '5px' }}
          >
            <AsyncSelect
              cacheOptions={true}
              isClearable={true}
              autoFocus={false}
              className="is-small"
              escapeClearsValue={true}
              backspaceRemovesValue={true}
              isSearchable={true}
              openMenuOnFocus={false}
              openMenuOnClick={false}
              onKeyDown={keyDownHandler ?? undefined}
              onInputChange={inputChangeHandler}
              menuIsOpen={menuOpen}
              filterOption={null}
              isLoading={loading}
              name={'search'}
              onChange={onChangeHandler}
              loadOptions={loadOptions}
              placeholder={`Search for ${pathname}`}
              styles={searchForm}
              components={{
                DropdownIndicator: () => null,
                Group,
                IndicatorSeparator: () => null,
                Option
              }}
              value={null}
            />
            <div className="columns is-marginless" />
          </div>
        )}
        <div className="navbar-end is-flex">{renderRightNav()}</div>
      </div>
      <div
        className={`container navbar-search-mobile-container ${
          isSearchInputActive ? 'is-active' : ''
        }`}
      >
        <div className="navbar-search-mobile">
          <AsyncSelect
            cacheOptions={true}
            isClearable={true}
            autoFocus={false}
            className="is-small"
            escapeClearsValue={true}
            backspaceRemovesValue={true}
            isSearchable={true}
            openMenuOnFocus={false}
            openMenuOnClick={false}
            onKeyDown={keyDownHandler ?? undefined}
            onInputChange={inputChangeHandler}
            menuIsOpen={menuOpen}
            filterOption={null}
            isLoading={loading}
            name={'search'}
            onChange={onChangeHandler}
            loadOptions={loadOptions}
            placeholder={`Search for ${pathname}`}
            styles={searchForm}
            components={{
              DropdownIndicator: () => null,
              Group,
              IndicatorSeparator: () => null,
              Option
            }}
            value={null}
          />
          <div className="columns is-marginless" />
        </div>
      </div>
      <div
        className={`container navbar-menu-mobile ${
          isNavbarActive ? 'is-active' : ''
        }`}
      >
        <div className={`navbar-menu ${isNavbarActive ? 'is-active' : ''}`}>
          <div className="navbar-start">
            <NavLink
              to="/"
              exact={true}
              className="navbar-item"
              activeClassName={'is-active'}
            >
              Projects
            </NavLink>
            <NavLink
              to="/creatives"
              exact={true}
              className="navbar-item"
              activeClassName={'is-active'}
            >
              Creatives
            </NavLink>
            <NavLink
              to="/project/new"
              exact={true}
              className="navbar-item"
              activeClassName={'is-active'}
            >
              Start a project
            </NavLink>
            <hr className="navbar-item-divider" />
            <NavLink to="/user" className="navbar-item">
              <div className="navbar-menu-mobile-item-avatar">
                <figure
                  className="avatar image is-32x32 m-r-1"
                  style={{
                    backgroundImage: `url(${getCompressedImagePath(
                      backgroundImageUrl!,
                      'thumbnail'
                    ) || profilePlaceholder})`
                  }}
                />
                {user && user.profile && (
                  <p className="is-size-6 m-b-0">{user.profile.name}</p>
                )}
              </div>
              <p className="m-t-1">Projects & My Projects</p>
            </NavLink>
            <hr className="navbar-item-divider" />
          </div>
          <div className="navbar-end">
            <span className="navbar-item" onClick={Api.logout}>
              Sign Out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
