import { useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import type { FunctionComponent } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import profilePlaceholder from '../../../assets/images/profile/profile-placeholder.png'
import editIcon from '../../../assets/svg/edit.svg';
import userIcon from '../../../assets/svg/user-icon.svg';
import { PRESIGN_LINK } from '../../../graphql/PRESIGN_LINK';
import { SAVE_USER_AVATAR } from '../../../graphql/SAVE_USER_AVATAR';
import { GET_CURRENT_USER_me_avatar } from '../../../graphql/types/GET_CURRENT_USER';
import { getCompressedImagePath } from '../../../utils/getCompressedImagePath';
import { EUploadTypes, formatFilename, uploadToS3 } from '../../Upload';

interface IUserAvatarProps {
  avatar: GET_CURRENT_USER_me_avatar
}

const UserAvatar: FunctionComponent<IUserAvatarProps> = (
  props: IUserAvatarProps
) => {
  const [file, setFile] = useState<File | null>(null);
  const [isAvatarHovered, setIsAvatarHovered] = useState(false);
  const saveAvatarCompleted = async () =>
    toast.success('User avatar successfully updated.');
  const [preSignLink] = useMutation(PRESIGN_LINK);
  const [saveUserAvatar] = useMutation(SAVE_USER_AVATAR, {
    onCompleted: saveAvatarCompleted
  });

  const backgroundImageUrl = file
    ? URL.createObjectURL(file)
    : props.avatar
      ? getCompressedImagePath(props.avatar.fileUrl, 'thumbnail')
      : userIcon;

  const toggleAvatarHover = () => setIsAvatarHovered(!isAvatarHovered);

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    async function preSignUploadAndSaveDB(uploadFile: File) {
      try {
        const uploadType = EUploadTypes.avatar;
        const signedLink = await preSignLink({
          variables: {
            fileName: formatFilename(uploadFile.name),
            fileType: uploadFile.type,
            uploadType
          }
        });

        const { signedRequest, url } = signedLink.data.preSignLink;
        await uploadToS3(uploadFile, signedRequest);

        await saveUserAvatar({
          variables: { url }
        });
      } catch (e) {
        throw new Error(e.message);
      }
    }

    if (acceptedFiles.length > 0) {
      await setFile(acceptedFiles[0]);
      await preSignUploadAndSaveDB(acceptedFiles[0]);
    }
  }, []); // eslint-disable-line

  const onDropRejected = () =>
    toast.error('You can not upload files more than 5MB.', {
      autoClose: false
    });

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize: 5000000, // 5MB
    onDrop,
    onDropRejected
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className="page-user-profile-modal-card-avatar is-relative has-cursor-pointer"
        onMouseEnter={toggleAvatarHover}
        onMouseLeave={toggleAvatarHover}
      >
        <figure
          className="is-96x96 avatar image m-b-1"
          style={{ backgroundImage: `url(${backgroundImageUrl || profilePlaceholder})` }}
        />
        <div
          className={`page-user-profile-modal-card-avatar-edit is-absolute ${isAvatarHovered &&
            'is-opaque'}`}
        >
          <button
            className="page-user-profile-modal-card-avatar-edit-button button has-background-grey has-height-auto has-text-grey-dark is-borderless is-small is-absolute"
            aria-controls="edit-avatar"
          >
            <span className="icon">
              <img src={editIcon} alt="edit icon" />
            </span>
            <span>Edit</span>
          </button>
        </div>
      </div>
      <figcaption className="is-light is-size-8">
        Photo size under 1 MB
      </figcaption>
    </div>
  );
};

export default UserAvatar;
