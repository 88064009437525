import { gql } from '@apollo/client';

export const GET_PAGINATED_ROOMS = gql`
  query GET_PAGINATED_ROOMS($limit: Float!, $page: Float!) {
    getPaginatedRooms(limit: $limit, page: $page) {
      __typename
      id
      createdAt
      recentMessage
      hasUnreadMessage

      owner {
        id
        email

        profile {
          id
          name
        }
      }

      members {
        email
        id

        profile {
          id
          name
        }
      }
    }
  }
`;
