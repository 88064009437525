import { Formik, FormikValues } from 'formik';
import React, { Fragment, useContext } from 'react';

import { MessageContext } from '../../contexts/messageContext';
import FormField from '../FormField';

const MessageReplyForm = () => {
  const { actions, currentRoom } = useContext(MessageContext);

  const renderForm = (formikData: FormikValues) => {
    const {
      values,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      dirty
    } = formikData;

    return (
      <form
        onSubmit={handleSubmit}
        className="page-messages-column-footer-form"
      >
        <FormField
          name="reply"
          type="text"
          placeholder="Write a reply..."
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.reply}
          multiline={true}
          additionalInputClasses="page-messages-column-footer-form-textarea"
        />

        <button
          type="submit"
          className={`button page-messages-column-footer-form-button is-primary has-text-white has-height-auto has-text-weight-semibold is-flex ${isSubmitting &&
            'is-loading'} ${values.reply.length <= 0 &&
            'has-background-grey is-light'}`}
          disabled={!dirty || isSubmitting}
        >
          <span className="is-small">Reply</span>
        </button>
      </form>
    );
  };

  return (
    <Fragment>
      <footer className="card-footer page-messages-column-card-footer is-block is-absolute">
        <Formik
          initialValues={{ reply: '' }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            if (values.reply === '') {
              return;
            }

            await actions.handleCreateMessage(
              currentRoom.data.getRoom.id,
              values.reply
            );

            setSubmitting(false);
            resetForm();
          }}
        >
          {renderForm}
        </Formik>
      </footer>
    </Fragment>
  );
};

export default MessageReplyForm;
